<template>
  <div class="edit-approval">
    <h2 class="edit-approval__header">
      {{ currentHeader }}
    </h2>
    <esmp-tabs :animated="false" v-model="activeTab">
      <esmp-tab-pane
        label="Конструктор"
        name="construct"
      >
        <service-form
          v-model="elementList"
          :is-control-sorting="false"
          :controls="controlList"
        />
      </esmp-tab-pane>
      <esmp-tab-pane
        label="Настройки формы"
        name="setting"
      >
        <validation-observer ref="settings">
          <div class="edit-approval__settings">
            <div class="edit-approval__setting">
              <div class="edit-approval__setting-name">
                Название формы
              </div>
              <div class="edit-approval__setting-field">
                <validation-provider
                  name="Название формы"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <esmp-input v-model="setting.name" :error-message="errors[0]" />
                </validation-provider>
              </div>
            </div>
            <div v-if="!isDefaultForm" class="edit-approval__setting">
              <div class="edit-approval__setting-name">
                Тип согласования
              </div>
              <div class="edit-approval__setting-field">
                <validation-provider
                  name="Тип согласования"
                  v-slot="{ errors }"
                >
                  <esmp-select
                    multiple
                    :error-message="errors[0]"
                    v-model="setting.approvalTypes"
                  >
                    <esmp-select-option
                      v-for="item in approvalFormTypes"
                      :value="item.name"
                      :key="item.value"
                    >
                      {{ item.name }}
                    </esmp-select-option>
                  </esmp-select>
                </validation-provider>
              </div>
            </div>
            <div class="edit-approval__setting">
              <div class="edit-approval__setting-name">
                Тип действия
              </div>
              <div class="edit-approval__setting-field">
                <validation-provider
                  name="Тип действия"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <esmp-select
                    :disabled="isDefaultForm"
                    :error-message="errors[0]"
                    v-model="setting.actionType"
                  >
                    <esmp-select-option
                      v-for="item in actionList"
                      :value="item.value"
                      :key="item.value"
                    >
                      {{ item.name }}
                    </esmp-select-option>
                  </esmp-select>
                </validation-provider>
              </div>
            </div>
            <div v-if="!isDefaultForm" class="edit-approval__setting">
              <div class="edit-approval__setting-name">
                Услуга
              </div>
              <div class="edit-approval__setting-field">
                <validation-provider
                  name="Услуга"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <esmp-select
                    placeholder="Поиск услуг"
                    :remote-method="debouncedSearchMethod"
                    :loading="searchLoading"
                    ref="select"
                    filterable
                    :value="setting.serviceId"
                    :error-message="errors[0]"
                    @on-select="setSelectedVal"
                  >
                    <esmp-select-option
                      v-for="r in searchResults"
                      :key="r.id"
                      :value="r.id"
                    >
                      {{ r.name }}
                    </esmp-select-option>
                  </esmp-select>
                </validation-provider>
              </div>
            </div>
          </div>
        </validation-observer>
      </esmp-tab-pane>
    </esmp-tabs>
    <div
      :class="['page__action-buttons', { 'page__action-buttons--wide': isCollapsedMenu}]"
    >
      <esmp-button
        class="page__action-button"
        @click="save"
      >
        Сохранить
      </esmp-button>
      <esmp-button
        class="page__action-button"
        @click="cancel"
        view="outline"
      >
        Отменить
      </esmp-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';

import { mappedControls, setOrder, setTechId } from '@/helpers/controls';
import { SEARCH_DELAY } from '@/constants/search';
// TODO: удалить, после того, как бэк доделает в нужном формате
import { ACTION_LIST } from '@/constants/approval';
import ServiceForm from '@/components/service-form';

export default {
  name: 'EditApproval',
  components: {
    ServiceForm,
  },
  data() {
    return {
      searchLoading: false,
      activeTab: 'construct',
      controlList: [],
      elementList: [],
      actionList: Object.freeze(ACTION_LIST),
      approvalFormTypes: [],
      searchResults: [],
      setting: {
        name: '',
        techName: '',
        comment: '',
        approvalTypes: [],
        actionType: null,

        serviceId: null,
        serviceName: null,
        sourceSystem: null,
      },
    };
  },
  computed: {
    ...mapState('sidebar', ['isCollapsedMenu']),
    currentHeader() {
      if (this.isDefaultForm) return 'Редактирование формы согласования по умолчанию';
      return this.isEditMode ? 'Редактирование формы согласования' : 'Создание формы согласования';
    },
    isEditMode() {
      return !!this.$route.params.id;
    },
    isDefaultForm() {
      return this.$route.query.isDefault;
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    getControls() {
      return this.$API.controls.getApprovalControls().then(({ data }) => {
        this.controlList = mappedControls(data) || [];
      });
    },
    getApprovalTypes() {
      return this.$API.approval.getApprovalTypes().then(({ data }) => {
        this.approvalFormTypes = data.map((type, index) => ({
          value: index,
          name: type,
        }));
      });
    },
    getApprovalActions() {
      return this.$API.approval.getApprovalActions().then(({ data }) => {
        this.actionList = data;
      });
    },
    searchMethod(query) {
      if (!query) {
        this.searchResults = [];
      } else {
        this.searchLoading = true;
        this.$API.services.simpleSearch(query)
          .then(({ data }) => {
            this.searchResults = data;
          })
          .finally(() => {
            this.searchLoading = false;
          });
      }
    },
    setSelectedVal(obj) {
      const service = this.searchResults.find((r) => r.id === obj.value);
      this.setting.serviceId = service?.id || null;
      this.setting.serviceName = service?.name || null;
      this.setting.sourceSystem = service?.sourceSystem || null;
    },
    async save() {
      const isValid = await this.$refs.settings.validate();
      if (!isValid) {
        this.activeTab = 'setting';
        this.$EsmpNotify.$show('Невалидные настройки', 'error');
        return;
      }

      const dto = cloneDeep(this.setting);
      dto.isDefault = !!this.isDefaultForm;
      dto.formItems = setOrder(cloneDeep(this.elementList));

      const saveMethod = (this.isEditMode || dto.isDefault) ? 'updateApproval' : 'createApproval';

      this.setLoading({ key: 'page', value: true });
      this.$API.approval[saveMethod](dto)
        .then(() => {
          this.$EsmpNotify.$show('Форма согласования успешно сохранена', 'success');
          this.$router.push({ name: 'ApprovalsBuilder' });
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    cancel() {
      this.$router.push({ name: 'ApprovalsBuilder' });
    },
    setForm(data) {
      this.elementList = setTechId(data.formItems);
      this.setting = omit(data, 'formItems');
      this.searchResults = [{
        id: data.serviceId,
        name: data.serviceName,
        sourceSystem: data.sourceSystem,
      }];
    },
    init() {
      if (this.isEditMode) {
        this.setLoading({ key: 'page', value: true });
        this.$API.approval.getApprovalForm(this.$route.params.id)
          .then(({ data }) => {
            this.setForm(data);
          })
          .finally(() => {
            this.setLoading({ key: 'page', value: false });
          });
      } else if (this.isDefaultForm) {
        this.$API.approval.getApprovalDefaultForm(this.$route?.query?.action)
          .then(({ data }) => {
            this.setForm(data);
          })
          .finally(() => {
            this.setLoading({ key: 'page', value: false });
          });
      }
    },
  },
  created() {
    this.getControls();
    this.getApprovalTypes();
    // TODO: вернуть, после того, как бэк доделает в нужном формате
    // this.getApprovalActions();
    this.debouncedSearchMethod = debounce(this.searchMethod, SEARCH_DELAY);
    this.init();
  },
};
</script>

<style lang="scss">
.edit-approval {
  padding-bottom: 80px;
  &__header {
    margin-bottom: $base-gutter;
  }
  &__settings {
    background-color: $color-white;
    padding: $base-gutter * 1.7;
    border-radius: $base-border-radius * 2.5;
  }
  &__setting {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $base-gutter;
    margin-bottom: $base-gutter;
  }
  &__setting-name {
    width: 350px;
    text-align: right;
    color: $color-grayscale-60;
  }
  &__setting-field {
    width: 50%;
  }
}
</style>
